import {TypesPpTestKitData} from "../../../generated";
import {H3} from "../../common/Headline";
import React, {useState} from "react";
import {ErrorMessage} from "../../common/ErrorMessage";
import {GetAggression, GetAppetite, GetBodyFigure, GetBrushing, GetEnvironments, GetExerciseTime, GetFrequencyExcrement, GetFrequencyWalking, GetMainFood, GetShampoo, GetUneasiness} from "../../../utility/Utility";
import {YesNo} from "./YesNo";
import {Button, ButtonColor, ButtonIcon} from "../../common/Button";
import styled from "styled-components";
import {Dog} from "../body_shape/Dog";
import {Cat} from "../body_shape/Cat";

interface Props {
    errors: { [key: string]: string };
    onUpdate: (next: TypesPpTestKitData) => void;
    ppTestKit: TypesPpTestKitData;
}

export const Habit = ({errors, onUpdate, ppTestKit}: Props) => {

    const [isDogModal, setIsDogModal] = useState<boolean>(false);
    const [isCatModal, setIsCatModal] = useState<boolean>(false);
    const [isDisabledWorries, setIsDisableWorries] = useState<boolean>(false);

    // チェックボックス変更
    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const next: any = ppTestKit;
        // チェクボックスは、checked === true で文字列1、0を切り替える
        next[e.currentTarget.name] = e.currentTarget.checked ? "1" : "0";

        if (e.currentTarget.name === "is_worry_nothing") {
            setIsDisableWorries(e.currentTarget.checked);

            if (e.currentTarget.checked) {
                next.is_worry_bad_hair = "0";
                next.is_worry_bad_breath = "0";
                next.is_worry_body_odor = "0";
                next.is_worry_eat_excrement = "0";
                next.is_worry_frequent_urination = "0";
                next.is_worry_increased_salivation = "0";
                next.is_worry_dental_tartar = "0";
                next.is_worry_smell_of_excrement = "0";
                next.is_worry_itchy_ears = "0";
                next.is_worry_smell_of_ears = "0";
                next.is_worry_weight_easily = "0";
                next.is_worry_lost_weight = "0";
                next.is_worry_warts = "0";
                next.is_worry_any_other = "0";
                next.is_worry_any_other_comment = null;
            }
        }

        if (e.currentTarget.name === "is_homemade_mainfood_type_other" && !e.currentTarget.checked) {
            // 食事について、手作りの場合のその他のチェックを外した場合、コメントをクリア
            next.is_homemade_mainfood_type_comment = "";
        }

        if (e.currentTarget.name === "is_worry_any_other" && !e.currentTarget.checked) {
            // 気になること、その他のチェックを外した場合、コメントをクリア
            next.is_worry_any_other_comment = "";
        }

        onUpdate(next);

    };

    // その他フォーム変更
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const next: any = ppTestKit;
        next[e.currentTarget.name] = e.currentTarget.value;


        // 食事について変更時
        if (e.currentTarget.name === "is_main_food") {

            if (e.currentTarget.value !== "3") {
                // 手作りでない場合、手作り項目を空にする
                next.is_homemade_mainfood_type_meat = "0";
                next.is_homemade_mainfood_type_fish = "0";
                next.is_homemade_mainfood_type_vegetable = "0";
                next.is_homemade_mainfood_type_fruit = "0";
                next.is_homemade_mainfood_type_other = "0";
                next.is_homemade_mainfood_type_comment = "";
            }

            if (e.currentTarget.value !== "4") {
                // その他でない場合、その他を空にする
                next.is_main_food_comment = "";
            }
        }

        onUpdate(next);
    };

    // 分離不安のオプションリストを作成
    // mapなのでちょっと違う。
    const getUneasinessOptions = (name: string): JSX.Element[] => {
        const map = GetUneasiness();
        const elements: JSX.Element[] = [];
        map.forEach((val, key) => {
            elements.push(<option value={key} key={`${name}-${key}`}>{val}</option>);
        });
        return elements;
    };

    // ブラッシング頻度のリストを返す
    const getBrushing = (): JSX.Element => {
        const map = GetBrushing();
        const elements: JSX.Element[] = [];
        map.forEach((val, key) => {
            elements.push(<label key={`is_frequency_brushing-${key}`}>
                <input type="radio" name="is_frequency_brushing" value={key} checked={ppTestKit.is_frequency_brushing === key} onChange={onChange}/>
                {val}
            </label>);
        });
        return <React.Fragment>
            {elements}
        </React.Fragment>
    };

    // シャンプー頻度のリストを返す
    const getShampoo = (): JSX.Element => {
        const map = GetShampoo();
        const elements: JSX.Element[] = [];
        map.forEach((val, key) => {
            elements.push(<label key={`is_frequency_shampoo-${key}`}>
                <input type="radio" name="is_frequency_shampoo" value={key} checked={ppTestKit.is_frequency_shampoo === key} onChange={onChange}/>
                {val}
            </label>);
        });
        return <React.Fragment>
            {elements}
        </React.Fragment>
    };

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setIsDogModal(false);
        setIsCatModal(false);
    };

    const environments = GetEnvironments();
    const frequencyWalking = GetFrequencyWalking();
    const exerciseTime = GetExerciseTime();
    const frequencyExcrement = GetFrequencyExcrement();
    const mainFood = GetMainFood();
    const appetites = GetAppetite();
    const bodyFigures = GetBodyFigure();
    const aggression = GetAggression();

    return <StyledHabit className="box">
        <H3 title="生活習慣について" subtitle="condition"/>

        <div className="form-wrapper">

            {/*飼育環境*/}
            <div className="form-group center">
                <label className="req">飼育環境</label>
                <div>
                    <div className="check">
                        {Object.keys(environments).map((key, i) => {
                            return <label key={`is_environment-${i}`}>
                                <input type="radio" name="is_environment" value={key} checked={ppTestKit.is_environment === key} onChange={onChange}/>
                                {environments[key]}
                            </label>
                        })}

                    </div>

                    <ErrorMessage message={errors["is_environment"]}/>
                </div>
            </div>

            {/*散歩頻度*/}
            <div className="form-group center">
                <label className="req">散歩頻度</label>
                <div>
                    <select name="is_frequency_walking" value={ppTestKit.is_frequency_walking ?? ""} onChange={onChange}>
                        <option value="">-- 選択してください</option>
                        {Object.keys(frequencyWalking).map((key, i) => {
                            return <option key={`is_frequency_walking-${i}`} value={key}>{frequencyWalking[key]}</option>
                        })}
                    </select>

                    <ErrorMessage message={errors["is_frequency_walking"]}/>

                </div>
            </div>

            {/*１日の運動時間*/}
            <div className="form-group center">
                <label className="req">１日の運動時間<small>（お散歩を含む）</small></label>
                <div>
                    <select name="is_exercise_time_oneday" value={ppTestKit.is_exercise_time_oneday ?? ""} onChange={onChange}>
                        <option value="">-- 選択してください</option>
                        {Object.keys(exerciseTime).map((key, i) => {
                            return <option key={`is_exercise_time_oneday-${i}`} value={key}>{exerciseTime[key]}</option>
                        })}
                    </select>

                    <ErrorMessage message={errors["is_exercise_time_oneday"]}/>

                </div>
            </div>

            {/*1日のうんちの回数*/}
            <div className="form-group center">
                <label className="req">1日のうんちの回数</label>
                <div>
                    <select name="is_frequency_excrement" value={ppTestKit.is_frequency_excrement ?? ""} onChange={onChange}>
                        <option value="">-- 選択してください</option>
                        {Object.keys(frequencyExcrement).map((key, i) => {
                            return <option key={`is_frequency_excrement-${i}`} value={key}>{frequencyExcrement[key]}</option>
                        })}
                    </select>
                    <ErrorMessage message={errors["is_frequency_excrement"]}/>
                </div>
            </div>

            {/*食事について*/}
            <div className="form-group center">
                <label className="req">食事について</label>
                <div className="food-area">
                    <select name="is_main_food" value={ppTestKit.is_main_food ?? ""} onChange={onChange}>
                        <option value="">-- 選択してください</option>
                        {Object.keys(mainFood).map((key, i) => {
                            return <option key={`is_main_food-${i}`} value={key}>{mainFood[key]}</option>
                        })}
                    </select>
                    <ErrorMessage message={errors["is_main_food"]}/>

                    <p className="hint2">※複数該当する場合は、与えている割合が一番高いものを選択してください。</p>

                    {/*手作りの場合のみ表示*/}
                    {ppTestKit.is_main_food === "3" && <div className="row bb pb15 mb15">
                        <label>具体的な食べ物</label>
                        <div>
                            <div className="check mb15">
                                <label>
                                    <input type="checkbox" name="is_homemade_mainfood_type_meat" checked={ppTestKit.is_homemade_mainfood_type_meat === "1"} onChange={onCheckChange}/>
                                    肉
                                </label>
                                <label>
                                    <input type="checkbox" name="is_homemade_mainfood_type_fish" checked={ppTestKit.is_homemade_mainfood_type_fish === "1"} onChange={onCheckChange}/>
                                    魚
                                </label>
                                <label>
                                    <input type="checkbox" name="is_homemade_mainfood_type_vegetable" checked={ppTestKit.is_homemade_mainfood_type_vegetable === "1"} onChange={onCheckChange}/>
                                    野菜
                                </label>
                                <label>
                                    <input type="checkbox" name="is_homemade_mainfood_type_fruit" checked={ppTestKit.is_homemade_mainfood_type_fruit === "1"} onChange={onCheckChange}/>
                                    果物
                                </label>
                            </div>


                            <div className="inline">
                                <div className="check">
                                    <label>
                                        <input type="checkbox" name="is_homemade_mainfood_type_other" checked={ppTestKit.is_homemade_mainfood_type_other === "1"} onChange={onCheckChange}/>
                                        その他：
                                    </label>
                                </div>
                                <input type="text" name="is_homemade_mainfood_type_comment" value={ppTestKit.is_homemade_mainfood_type_comment ?? ""} placeholder="食べ物の名前を入力してください" onChange={onChange}/>
                            </div>
                            <ErrorMessage
                                message={errors["is_homemade_mainfood_type_comment"]}
                                top={55}
                            />

                        </div>
                    </div>}

                    {/*その他の場合のみ表示*/}
                    {ppTestKit.is_main_food === "4" && <div className="row">
                        <div className="inline">
                            <span>具体的な食べ物</span>
                            <input type="text" name="is_main_food_comment" value={ppTestKit.is_main_food_comment ?? ""} placeholder="食べ物の名前を入力してください" onChange={onChange}/>
                        </div>
                        <ErrorMessage message={errors["is_main_food_comment"]} top={55}/>
                    </div>}

                </div>
            </div>

            {/*食欲について*/}
            <div className="form-group center">
                <label className="req">食欲について</label>
                <div>
                    <select name="is_appetite" value={ppTestKit.is_appetite ?? ""} onChange={onChange}>
                        <option value="">-- 選択してください</option>
                        {Object.keys(appetites).map((key, i) => {
                            return <option key={`is_appetite-${i}`} value={key}>{appetites[key]}</option>
                        })}
                    </select>
                    <ErrorMessage message={errors["is_appetite"]}/>
                </div>
            </div>

            {/*日常的な乳酸菌サプリの摂取*/}
            <div className="form-group center">
                <label className="req">日常的な<br/>乳酸菌サプリの<br/>摂取</label>
                <div>
                    <YesNo name="is_lactic_supplement" value={ppTestKit.is_lactic_supplement ?? ""} onChange={onChange}/>
                    <ErrorMessage message={errors["is_lactic_supplement"]}/>
                </div>
            </div>

            {/*体型*/}
            <div className="form-group center">
                <label className="req">体型</label>
                <div className="appetite-area">
                    <div className="inline">
                        <select name="is_body_figure" value={ppTestKit.is_body_figure ?? ""} onChange={onChange}>
                            <option value="">-- 選択してください</option>
                            {Object.keys(bodyFigures).map((key, i) => {
                                return <option key={`is_body_figure-${i}`} value={key}>{bodyFigures[key]}</option>
                            })}
                        </select>
                        <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} onClick={() => setIsDogModal(true)}>犬の体型の基準</Button>
                        <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} onClick={() => setIsCatModal(true)}>猫の体型の基準</Button>
                    </div>
                    <ErrorMessage message={errors["is_body_figure"]}/>
                </div>
            </div>

            {/*攻撃性*/}
            <div className="form-group center">
                <label className="req">
                    攻撃性
                    <small className="ex-small">※吠える・噛む・歯をむき出す・唸るなどの行動がみられる場合を、攻撃性があると定義します。</small>
                </label>
                <div className="multi-select-area">

                    <div className="row bb">
                        <label>家族に対して</label>
                        <div>
                            <select name="is_aggression_for_family" value={ppTestKit.is_aggression_for_family ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {Object.keys(aggression).map((key, i) => {
                                    return <option key={`is_aggression_for_family-${i}`} value={key}>{aggression[key]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["is_aggression_for_family"]}/>
                        </div>
                    </div>
                    <div className="row">
                        <label>他の人間に対して</label>
                        <div>
                            <select name="is_aggression_for_stranger" value={ppTestKit.is_aggression_for_stranger ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {Object.keys(aggression).map((key, i) => {
                                    return <option key={`is_aggression_for_stranger-${i}`} value={key}>{aggression[key]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["is_aggression_for_stranger"]}/>
                        </div>
                    </div>

                </div>
            </div>

            {/*不安の感じやすさ*/}
            <div className="form-group center">
                <label className="req">
                    不安の感じやすさ
                </label>
                <div className="multi-select-area">

                    <div className="row bb">
                        <label>
                            <small>
                                飼い主と離れた時に、<br/>
                                大きな声で鳴いたり、<br/>
                                暴れたりする
                            </small>
                        </label>
                        <div>
                            <select name="is_uneasiness_separated_from_owner" value={ppTestKit.is_uneasiness_separated_from_owner ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {getUneasinessOptions("is_uneasiness_separated_from_owner")}
                            </select>
                            <ErrorMessage message={errors["is_uneasiness_separated_from_owner"]}/>
                        </div>
                    </div>
                    <div className="row">
                        <label>
                            <small>
                                飼い主が不在の時、<br/>
                                物を壊したり、<br/>
                                トイレを失敗する
                            </small>
                        </label>
                        <div>
                            <select name="is_uneasiness_damage_to_property" value={ppTestKit.is_uneasiness_damage_to_property ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {getUneasinessOptions("is_uneasiness_damage_to_property")}
                            </select>
                            <ErrorMessage message={errors["is_uneasiness_damage_to_property"]}/>
                        </div>
                    </div>

                </div>
            </div>

            {/*恐怖心*/}
            <div className="form-group center">
                <label className="req">
                    恐怖心
                </label>
                <div className="multi-select-area">

                    <div className="row bb">
                        <label>他の動物に対して</label>
                        <div>
                            <select name="is_fear_for_other_pets" value={ppTestKit.is_fear_for_other_pets ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {getUneasinessOptions("is_fear_for_other_pets")}
                            </select>
                            <ErrorMessage message={errors["is_fear_for_other_pets"]}/>
                        </div>
                    </div>

                    <div className="row bb">
                        <label>他の人間に対して</label>
                        <div>
                            <select name="is_fear_for_stranger" value={ppTestKit.is_fear_for_stranger ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {getUneasinessOptions("is_fear_for_stranger")}
                            </select>
                            <ErrorMessage message={errors["is_fear_for_stranger"]}/>
                        </div>
                    </div>

                    <div className="row">
                        <label>慣れない環境に対して</label>
                        <div>
                            <select name="is_fear_unfamiliar_environment" value={ppTestKit.is_fear_unfamiliar_environment ?? ""} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {getUneasinessOptions("is_fear_unfamiliar_environment")}
                            </select>
                            <ErrorMessage message={errors["is_fear_unfamiliar_environment"]}/>
                        </div>
                    </div>

                </div>
            </div>

            {/*ブラッシングの頻度*/}
            <div className="form-group center">
                <label className="req">ブラッシングの頻度</label>
                <div>
                    <div className="check">
                        {getBrushing()}
                    </div>
                    <ErrorMessage message={errors["is_frequency_brushing"]}/>
                </div>
            </div>

            {/*シャンプーの頻度*/}
            <div className="form-group center">
                <label className="req">シャンプーの頻度</label>
                <div>
                    <div className="check">
                        {getShampoo()}
                    </div>
                    <ErrorMessage message={errors["is_frequency_shampoo"]}/>
                </div>
            </div>

            {/*気になること*/}
            <div className="form-group center">
                <label className="req">気になること</label>
                <div className="worry-area">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_worry_nothing" checked={ppTestKit.is_worry_nothing === "1"} onChange={onCheckChange}/>
                            特になし
                        </label>
                    </div>
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_worry_bad_hair" checked={ppTestKit.is_worry_bad_hair === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            毛ヅヤが悪い気がする
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_bad_breath" checked={ppTestKit.is_worry_bad_breath === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            口臭が気になる
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_body_odor" checked={ppTestKit.is_worry_body_odor === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            体臭が気になる
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_eat_excrement" checked={ppTestKit.is_worry_eat_excrement === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            食糞する
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_increased_salivation" checked={ppTestKit.is_worry_increased_salivation === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            よだれが垂れる
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_smell_of_excrement" checked={ppTestKit.is_worry_smell_of_excrement === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            便が臭い
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_dental_tartar" checked={ppTestKit.is_worry_dental_tartar === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            歯石が気になる
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_frequent_urination" checked={ppTestKit.is_worry_frequent_urination === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            おしっこの回数が多い
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_itchy_ears" checked={ppTestKit.is_worry_itchy_ears === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            耳を痒がっている
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_smell_of_ears" checked={ppTestKit.is_worry_smell_of_ears === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            耳の匂いが気になる
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_weight_easily" checked={ppTestKit.is_worry_weight_easily === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            体重が増えやすい
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_lost_weight" checked={ppTestKit.is_worry_lost_weight === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            体重が増えにくい / 痩せている
                        </label>
                        <label>
                            <input type="checkbox" name="is_worry_warts" checked={ppTestKit.is_worry_warts === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            イボがある
                        </label>
                        <label className="other">
                            <input type="checkbox" name="is_worry_any_other" checked={ppTestKit.is_worry_any_other === "1"} onChange={onCheckChange} disabled={isDisabledWorries}/>
                            その他：
                        </label>
                        <input type="text" name="is_worry_any_other_comment" value={ppTestKit.is_worry_any_other_comment ?? ""} onChange={onChange} placeholder="気になる症状を記入してください" disabled={isDisabledWorries}/>
                    </div>
                    <ErrorMessage message={errors["is_worry_nothing"]}/>
                    <ErrorMessage message={errors["is_worry_any_other_comment"]}/>
                </div>
            </div>


        </div>

        {isDogModal && <Dog onClose={onModalClose}/>}
        {isCatModal && <Cat onClose={onModalClose}/>}


    </StyledHabit>
}

const StyledHabit = styled.div`
  // 食事についてエリア
  .food-area {
    .hint2 {
      border-bottom: 1px solid #E5EAE9;
      padding-bottom: 14px;
      margin-bottom: 14px;
    }

    .row {
      display: flex;
      align-items: flex - start;

      > label {
        font-size: 14px;
        width: 130px;
      }

      input[name=is_homemade_mainfood_type_comment] {
        width: 457px;
        height: 40px;
      }

      input[name=is_main_food_comment] {
        width: 638px;
        height: 40px;
      }

      &.bb {
        border-bottom: 1px solid #E5EAE9;
      }
    }
  }

  // 食欲エリアのボタン
  .appetite-area {
    .inline {
      select, button {
        margin: 0 30px 0 0;
      }
    }
  }

  // 多段のセレクトボックス汎用
  .multi-select-area {
    .row {
      display: flex;
      align-items: center;
      position: relative;

      label {
        font-size: 14px;
        line-height: 18px;
        width: 158px;

        small {
          font-size: 12px;
          line-height: 12px;
        }
      }

      &.bb {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #E5EAE9;
      }

    }
  }

  // 気になることエリア
  .worry-area {
    .check {
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      label {
        margin: 0 0 20px 0;
        width: 24%;

        &.other {
          width: auto;
        }
      }

      input[type=text] {
        height: 40px;
        width: 490px;
        margin-top: -19px;
      }

    }
  }

`;