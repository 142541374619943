import {Layout} from "../common/Layout";
import {H1, H3, H4} from "../common/Headline";
import React, {useContext, useEffect, useState} from "react";
import {adminPpTestKitApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {PpTestKitControllerStep2Request, TypesPpTestKitData} from "../../generated";
import {useNavigate, useParams} from "react-router-dom";
import {PartsPetDetail} from "../pp_pet/PartsPetDetail";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {InterviewSteps} from "./interview/InterviewSteps";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {Habit} from "./interview/Habit";
import {ErrorMessage} from "../common/ErrorMessage";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";

export const PpTestKitStep2 = () => {

    const params = useParams<{ sampleId: string }>();
    const navigate = useNavigate();
    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = React.useState<TypesPpTestKitData | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }

    }, []);

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit ?? null);

                if (!data.pp_test_kit.pp_pet) {
                    setDangerMessage("ご指定の検体IDにはまた申し込みがされていません。");
                    navigate(`/pp_test_kit`);
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

                navigate(`/pp_test_kit`);
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (!ppTestKit || !ppTestKit.pp_pet) {
        return <Layout title="検査申込管理"/>
    }

    const onUpdate = (next: TypesPpTestKitData) => {
        setPpTestKit((prev) => {
            return {...prev, ...next};
        });
    };

    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_test_kit/step1/${ppTestKit.sample_id}`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);
        setErrors({});

        const req: PpTestKitControllerStep2Request = ppTestKit as PpTestKitControllerStep2Request;

        adminPpTestKitApi.v1AdminPpTestKitStep2SampleIdPost(ppTestKit.sample_id, req)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit ?? null);
                navigate(`/pp_test_kit/confirm/${ppTestKit.sample_id}`);
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("入力内容に不足、または誤りがあります。もう一度、入力内容をご確認ください。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 同意チェックボックスの変更ハンドラー
    const onConsentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const next = ppTestKit;
        switch (e.currentTarget.name) {
            case "is_consent_for_testing":
                next.is_consent_for_testing = e.currentTarget.checked;
                break
            case "is_research_participation_consent":
                next.is_research_participation_consent = e.currentTarget.checked;
                break
        }
        onUpdate(next);
    };

    return <Layout title="検査申込管理">

        <StyledPpTestKitInterview onSubmit={onSubmit}>

            <H1 title="ペット情報の新規登録" subtitle="registration"/>

            <InterviewSteps offset={1}/>

            <div className="box">
                <H3 title={"ペットの情報"} subtitle="pet"/>
                <PartsPetDetail ppPet={ppTestKit.pp_pet}/>
            </div>

            <H4 title={"基礎情報の入力"}/>

            <Habit errors={errors} onUpdate={onUpdate} ppTestKit={ppTestKit}/>

            <div className="form-group check-area accept-area mb20">
                <label className="req">検査に関する同意</label>
                <div>
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_consent_for_testing" onChange={onConsentChange} checked={ppTestKit.is_consent_for_testing}/>
                            同意する
                        </label>
                        <a href={`${process.env.REACT_APP_FRONTEND_URL}/assets/document/apply_kit_policy_20240301.pdf`} target="_blank" rel="noreferrer" download="apply_kit_policy.pdf" className="btn-terms">検査申込規約</a>
                    </div>
                    <ErrorMessage message={errors["is_consent_for_testing"]}/>
                </div>
            </div>

            <div className="form-group check-area accept-area">
                <label className="req">研究への参加の同意</label>
                <div>
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_research_participation_consent" onChange={onConsentChange} checked={ppTestKit.is_research_participation_consent}/>
                            同意する
                        </label>
                        <a href={`${process.env.REACT_APP_FRONTEND_URL}/assets/document/research_participation_20240301.pdf`} target="_blank" rel="noreferrer" download="research_participation.pdf" className="btn-terms">研究参加同意書</a>
                    </div>
                    <ErrorMessage message={errors["is_research_participation_consent"]}/>
                </div>
            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onClickBack}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>内容を確認する</Button>
            </div>

        </StyledPpTestKitInterview>

    </Layout>

}

const StyledPpTestKitInterview = styled(StyledForm)`


    // 同意エリアだけ短い

    .accept-area {
        background-color: rgb(240, 232, 217);
        width: 500px;


        // 利用規約ボタン

        .btn-terms {
            font-size: 12px;
            height: 24px;
            width: auto;
            padding: 0 30px 0 20px;
            margin: 0;
            cursor: pointer;
            display: inline-block;
            letter-spacing: 1px;
            border-radius: 24px;
            background-color: ${MainColor};
            color: #fff;
            text-decoration: none;
            line-height: 24px;
            background-position: top 50% right 15px;
            background-size: 5px auto;
            background-repeat: no-repeat;
            background-image: url(${rightWhite});
        }

    }

`;
